<template>
    <section class="settings-payments">
        <h4 class="settings-payments__title sr-only h3">Ввод расходов ресторана</h4>
        <spinner class="settings-payments__spinner" v-if="status === 'loading'" />
        <error-message class="settings-payments__error-message" v-else-if="status === 'error'">
            Не удалось загрузить ввод расходов ресторана.
        </error-message>
        <template v-else>
            <month-picker class="settings-payments__datepicker settings-payments__datepicker_standard"
                name="paymentsDate"
                v-model="paymentsDateModel"
            >Месяц:</month-picker>
            <p class="settings-payments__tip">Суммы вводятся в рублях.</p>
            <form class="settings-payments__box box" @submit.prevent="submitPayments">
                <select class="settings-payments__select" v-model="restaurantId">
                    <option class="settings-payments__option"
                        v-for="restaurant in restaurants"
                        :key="restaurant.id"
                        :value="restaurant.id"
                    >
                        {{restaurant.title}}
                    </option>
                </select>
                <div class="settings-payments__controls">
                    <div class="settings-payments__control"
                        v-for="(parameter, parameterKey) in parameters"
                        :key="parameterKey"
                    >
                        <label class="settings-payments__label h3" :for="parameterKey">{{parameters[parameterKey].title}}</label>
                        <input class="settings-payments__input"
                            type="text"
                            :id="parameterKey"
                            :class="{ 'settings-payments__input_invalid': parameters[parameterKey].isValueInvalid }"
                            :value="parameters[parameterKey].inputValue"
                            @input="handlePaymentsInput(parameterKey, $event)"
                        >
                        <span class="settings-payments__error" v-if="parameters[parameterKey].errorText">
                            {{parameters[parameterKey].errorText}}
                        </span>
                    </div>
                </div>
                <div class="settings-payments__footer">
                    <button class="settings-payments__button settings-payments__button_save"
                        type="submit"
                        :disabled="isSubmitButtonDisabled"
                    >
                        {{submitButtonText}}
                    </button>
                </div>
            </form>
        </template>
    </section>
</template>

<script>
    import { mapState, mapGetters, mapActions, mapMutations } from "vuex";
    import MonthPicker from "@/components/MonthPicker";
    import { dateKey } from "@/helpers/daterange";

    export default {
        name: "SettingsPayments",
        components: {
            MonthPicker
        },
        data() {
            return {
                status: "loading",
                restaurantId: null,
                parameters: {
                    rent: {
                        title: "Аренда",
                        originalValue: null,
                        inputValue: null,
                        inputValueAsNumber: null,
                        isSameValue: true,
                        isValueInvalid: null,
                        errorText: ""
                    },
                    utilities: {
                        title: "Коммунальные платежи",
                        originalValue: null,
                        inputValue: null,
                        inputValueAsNumber: null,
                        isSameValue: true,
                        isValueInvalid: null,
                        errorText: ""
                    },
                    fuel: {
                        title: "ГСМ",
                        originalValue: null,
                        inputValue: null,
                        inputValueAsNumber: null,
                        isSameValue: true,
                        isValueInvalid: null,
                        errorText: ""
                    },
                    call_center_ratio: {
                        title: "Коэффициент колл-центра",
                        originalValue: null,
                        inputValue: null,
                        inputValueAsNumber: null,
                        isSameValue: true,
                        isValueInvalid: null,
                        errorText: ""
                    },
                    marketing_federal_ratio: {
                        title: "Федеральный маркетинг",
                        originalValue: null,
                        inputValue: null,
                        inputValueAsNumber: null,
                        isSameValue: true,
                        isValueInvalid: null,
                        errorText: ""
                    },
                    marketing_local: {
                        title: "Локальный маркетинг",
                        originalValue: null,
                        inputValue: null,
                        inputValueAsNumber: null,
                        isSameValue: true,
                        isValueInvalid: null,
                        errorText: ""
                    }
                },
                isSubmitButtonDisabled: true,
                submitButtonText: "Сохранить",
            };
        },
        computed: {
            ...mapState({
                paymentsDate: state => state.settings.payments.date,
                paymentsData: state => state.settings.payments.data
            }),
            ...mapGetters([
                "userRestaurants"
            ]),
            paymentsDateModel: {
                get() {
                    return this.paymentsDate;
                },
                set(date) {
                    this.setPaymentsDate(date);
                }
            },
            restaurants() {
                if (this.userRestaurants?.ids.length > 0) {
                    return Object.values(this.userRestaurants.byId);
                }
            },
            paymentsDateData() {
                return this.paymentsData?.[dateKey(this.paymentsDate)];
            },
            restaurant() {
                return this.paymentsDateData?.find(({ id }) => id === this.restaurantId);
            }
        },
        methods: {
            ...mapMutations([
                "setPaymentsDate",
            ]),
            ...mapActions([
                "requestPayments",
                "savePaymentsToDB",
                "createMessage"
            ]),
            resetForm() {
                Object.keys(this.parameters).forEach(parameter => {
                    this.parameters[parameter].originalValue = this.restaurant[parameter];
                    this.parameters[parameter].inputValue = this.restaurant[parameter];
                    this.parameters[parameter].inputValueAsNumber = this.restaurant[parameter];
                    this.parameters[parameter].isSameValue = true;
                    this.parameters[parameter].isValueInvalid = false;
                    this.parameters[parameter].errorText = "";
                });

                this.isSubmitButtonDisabled = true;
                this.submitButtonText = "Сохранить";
            },
            showData() {
                this.resetForm();
                this.status = "success";
            },
            showError() {
                this.status = "error";
            },
            loadPayments() {
                this.status = "loading";
                this.requestPayments(dateKey(this.paymentsDate)).then(this.showData, this.showError);
            },
            handlePaymentsInput(parameter, { target: { value }}) {
                let parameters = Object.values(this.parameters);

                this.parameters[parameter].inputValue = value;
                this.parameters[parameter].inputValueAsNumber = Number(value);

                this.parameters[parameter].isSameValue = this.parameters[parameter].inputValueAsNumber === this.parameters[parameter].originalValue;

                this.parameters[parameter].isValueInvalid =
                    Number.isNaN(this.parameters[parameter].inputValueAsNumber) ||
                    this.parameters[parameter].inputValueAsNumber === 0;

                this.parameters[parameter].errorText = this.parameters[parameter].isValueInvalid ? "Некорректное значение" : "";

                this.isSubmitButtonDisabled =
                    parameters.some(({ isValueInvalid }) => Boolean(isValueInvalid)) ||
                    parameters.every(({ isSameValue }) => Boolean(isSameValue));
            },
            submitPayments() {
                this.isSubmitButtonDisabled = true;
                this.submitButtonText = "Сохранение...";

                this.savePaymentsToDB({
                    date: dateKey(this.paymentsDate),
                    eng: this.restaurantId,
                    ...Object.fromEntries(Object.entries(this.parameters).map(
                        ([parameterKey, parameter]) => [parameterKey, parameter.inputValueAsNumber]
                    ))
                }).then(() => {
                    this.isSubmitButtonDisabled = true;
                    this.createMessage({
                        type: "success",
                        text: `Данные сохранены`
                    });
                }).catch(() => {
                    this.isSubmitButtonDisabled = false;
                    this.createMessage({
                        type: "failure",
                        text: `Не удалось сохранить данные`
                    });
                }).finally(() => {
                    this.submitButtonText = "Сохранить";
                });
            }
        },
        created() {
            this.restaurantId = this.restaurants?.[0]?.id;

            if (this.paymentsDateData) {
                this.showData();
            } else {
                this.loadPayments();
            }
        },
        watch: {
            paymentsDateData(paymentsDateData) {
                if (!paymentsDateData) {
                    this.loadPayments();
                } else {
                    this.showData();
                }
            },
            restaurant(restaurant) {
                if (restaurant) {
                    this.resetForm();
                }
            }
        },
    }
</script>

<style lang="scss">
    .settings-payments__title {
        margin-bottom: 10px;
    }
    .settings-payments__datepicker {
        &_standard {
            margin-bottom: 10px;
        }
    }
    .settings-payments__radio-group {
        display: flex;
        flex-flow: column nowrap;
        justify-content: flex-start;
        align-items: flex-start;
        margin-bottom: 10px;

        @include desktop {
            flex-direction: row;
            margin-bottom: 15px;
        }
    }
    .settings-payments__radio {
        & + & {
            margin-top: 5px;

            @include desktop {
                margin: 0 0 0 20px;
            }
        }
    }
    .settings-payments__tip {
        margin-bottom: 10px;
    }
    .settings-payments__box {
        max-width: 400px;
        padding: 24px 16px;
        display: flex;
        flex-flow: column nowrap;
        justify-content: flex-start;
        align-items: stretch;

        @include desktop {
            max-width: none;
        }
    }
    .settings-payments__controls {
        @include desktop {
            display: grid;
            grid-template-columns: repeat(6, 1fr);
            grid-gap: 15px;
        }
    }
    .settings-payments__control {
        display: flex;
        flex-flow: column nowrap;
        justify-content: flex-start;
        align-items: flex-start;
        padding-bottom: 16px;
        position: relative;

        & + & {
            margin-top: 8px;

            @include desktop {
                margin-top: 0;
            }
        }
    }
    .settings-payments__label {
        margin: 0 0 5px;
    }
    .settings-payments__input {
        width: 100%;
        padding: 8px 10px;
        border: 1px solid $gray-line;
        border-radius: 4px;

        font-size: 16px;
        line-height: 1;

        &[disabled] {
            background-color: $gray-line;
        }

        @include desktop {
            font-size: 20px;
        }

        &:focus {
            outline: none;
        }

        &_invalid {
            border-color: $red-secondary;
        }
    }
    .settings-payments__error {
        color: $red-secondary;
        font-size: 14px;
        line-height: 1;
        position: absolute;
        left: 0;
        bottom: 0;
    }
    .settings-payments__footer {
        display: flex;
        flex-flow: column nowrap;
        justify-content: flex-start;
        align-items: center;
        padding-top: 20px;
        position: relative;
    }
    .settings-payments__button {
        padding: 10px;
        border-radius: 6px;

        &[disabled] {
            background-color: $gray-form;
        }

        &_save {
            min-width: 100px;
            color: #fff;
            font-size: 14px;
            background-color: rgba($red-secondary, 0.7);

            @include desktop {
                min-width: 120px;
                font-size: 16px;
            }
        }
    }
    .settings-payments__select {
        min-width: 240px;
        max-width: 300px;
        padding: 4px 8px;
        margin-bottom: 25px;
        border-color: $gray-line;
        border-radius: 4px;

        &:focus {
            outline: none;
        }
    }
</style>
